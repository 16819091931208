<template>

  <v-card flat>
    <v-card-title class="d-flex justify-space-between">
      <span>Карточка пользователя</span>
      <v-btn icon @click="close"><v-icon>mdi-window-close</v-icon></v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field :value="member.surname" dense label="Фамилия" readonly></v-text-field>
          <v-text-field :value="member.name" dense label="Имя" readonly></v-text-field>
          <v-text-field :value="member.patronymic" dense label="Отчество" readonly></v-text-field>
          <v-text-field :value="member.email" dense label="E-Mail" readonly></v-text-field>
          <v-text-field
            v-for="(item, i) in member.mobile"
            :key="i"
            :value="item | VMask('+7 (###) ###-####')"
            dense
            label="Телефон"
            readonly
          ></v-text-field>
          <v-text-field :value="formatDt(member.birthday)" label="День рождения" readonly></v-text-field>
          <v-select
            v-model="member.prior_comm"
            :items="prior_comm"
            readonly
            label="Приоритетный способ связи"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>
export default {
  name: 'MemberView',
  data: function () {
    return {
      isPartner: false, // true - контрагент уже создан
      prior_comm: [
        { value: 'email', text: 'E-Mail' },
        { value: 'phone', text: 'Телефон' }
      ],
      member: {
        mobile: []
      }
    }
  },
  created () {
  },
  mounted () {
    this.getData()
  },
  computed: {
    memberId: function () {
      return this.$store.state.rn.params.id
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('user/GET_MEMBER_FORM', this.memberId).then(response => {
        this.member = response
      })
    },
    formatDt (value) {
      return value ? this.$moment(value).format('DD.MM.YYYY') : ''
    },
    close () {
      this.$store.commit('rn/CLOSE_VIEW')
    }
  },
  watch: {
    memberId: function () {
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>
